import React from 'react';
import Next from './next.component';
import Previous from './previous.component';
import Submit from './submit.component';
import { FIRST_INDEX } from '../../../utils/constants';

const ButtonBar: React.FC<{ gradeState: any, GradeStateActionHandler: (gradeAction: any) => void }> = (props) => {
  const { currentGradeIndex } = props.gradeState;

  const isRenderSubmit = (): boolean => {
    // Left Eye
    const leftEye = props.gradeState.leftEye;
    const protocolViolationLeftEye = leftEye.protocolViolationGrade !== '';
    const sufficientQualityLeftEye = leftEye.sufficientQualityGrade !== '';
    const retinopathyGradeLeftEye = leftEye.retinopathyGrade !== '';
    const macularEdemaGradeLeftEye = leftEye.macularEdemaGrade !== '';

    // Right
    const rightEye = props.gradeState.rightEye;
    const protocolViolationRightEye = rightEye.protocolViolationGrade !== '';
    const sufficientQualityRightEye = rightEye.sufficientQualityGrade !== '';
    const retinopathyGradeRightEye = rightEye.retinopathyGrade !== '';
    const macularEdemaGradeRightEye = rightEye.macularEdemaGrade !== '';

    return protocolViolationLeftEye
      && sufficientQualityLeftEye
      && retinopathyGradeLeftEye
      && macularEdemaGradeLeftEye
      && protocolViolationRightEye
      && sufficientQualityRightEye
      && retinopathyGradeRightEye
      && macularEdemaGradeRightEye
  }

  return (
    <div className={`btn-group btn-wrapper`}>
      {
        <Previous
          GradeStateActionHandler={props.GradeStateActionHandler}
          active={currentGradeIndex > FIRST_INDEX}
        />
      }
      {
        <Submit
          GradeStateActionHandler={props.GradeStateActionHandler}
          active={() => isRenderSubmit()}
          gradeState={props.gradeState}
        />
      }
      {
        <Next
          GradeStateActionHandler={props.GradeStateActionHandler}
          active={currentGradeIndex < 4}
        />
      }
    </div>
  )
}

export default ButtonBar;