import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { NotesAction } from '../actions';
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles(() => ({
  NotesField: {
    width: '100%'
  }
}));

const Notes: React.FC<{
  GradeStateActionHandler: (gradeAction: any) => void,
  eyeSide: string,
  showBtnBar: boolean,
  gradeState: any
}> = (props) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const grade = (event.target as HTMLInputElement).value;
    props.GradeStateActionHandler(new NotesAction(grade))
  }
  const { notes } = props.gradeState;
  return (
    <Slide direction={props.gradeState.slideSide} in={true} mountOnEnter unmountOnExit timeout={300} >
      <div>
        <TextField
          className={classes.NotesField}
          id="outlined-multiline-static"
          label="Notes"
          multiline
          rows={6}
          variant="outlined"
          value={notes}
          autoFocus
          onChange={handleChange}
        />
      </div>
    </Slide>
  )
}

export default Notes;