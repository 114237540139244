export const PROTOCOL_VIOLATION_INDEX = 0;
export const SUFFICIENT_QUALITY_INDEX = 1;
export const RETINOPATHY_INDEX = 2;
export const MACULAR_EDEMA_INDEX = 3;
export const GLAUCOMA_INDEX = 4;
export const NOTES_INDEX = 5;
export const FIRST_INDEX = 0;
export const LAST_INDEX = 6;

export const PROTOCOL_VIOLATION_GRADE = 'PROTOCOL_VIOLATION_GRADE';
export const NO_PROTOCOL_VIOLATION = '1';
export const PROTOCOL_VIOLATION = '2';

export const SUFFICIENT_QUALITY_GRADE = 'SUFFICIENT_QUALITY_GRADE';
export const SUFFICIENT_QUALITY = '1';
export const INSUFFICIENT_QUALITY = '2';

export const RETINOPATHY_GRADE = 'RETINOPATHY_GRADE';
export const NO_APPARENT_RETINOPATHY = '1';
export const MILD_RETINOPATHY = '2';
export const MODERATE_RETINOPATHY = '3';
export const SEVERE_RETINOPATHY = '4';
export const PROLIFERATIVE_RETINOPATHY = '5';

export const MACULAR_EDEMA_GRADE = 'MACULAR_EDEMA_GRADE';
export const GLAUCOMA_GRADE = 'GLAUCOMA_GRADE';
export const ABSENT = '1';
export const PRESENT = '2';

export const NOTES = 'NOTES';
export const NOTES_FOCUS = 'NOTES_FOCUS';
export const NOTES_UNFOCUS = 'NOTES_UNFOCUS';

export const NEXT_GRADE = 'NEXT_GRADE';
export const SUBMIT_GRADES = 'SUBMIT_GRADES';
export const PREVIOUS_GRADE = 'PREVIOUS_GRADE';

export const CLEAR_GRADES = 'CLEAR_GRADES';

export const LEFT_EYE = 'leftEye';
export const RIGHT_EYE = 'rightEye';
