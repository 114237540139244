export const userInfo = () => {
  return {
    url: process.env.REACT_APP_USER_POOL_DOMAIN as string + 'oauth2/userInfo',
    method: 'get'
  }
}

export const fetchExam = () => {
  return {
    url: 'api/checkout/:username',
    method: 'get'
  }
}

export const doGrade = () => {
  return {
    url: 'api/grade',
    method: 'post'
  }
}

export const updatePassword = () => {
  return {
    url: 'api/changePassword',
    method: 'post'
  }
}