import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  gradeControlLabel: {
    width: '100%',
    margin: '0 0 0 0 !important'
  }
}));

const Grade: React.FC<{ value: string, label: string, checked: boolean, name: string, onClick: (event: any) => any }> = (props) => {
  const classes = useStyles();
  return (
    <FormControlLabel className={classes.gradeControlLabel} value={props.value} control={
      <Radio
        color="primary"
        value={props.value}
        checked={props.checked}
        name={props.name}
        onChange={props.onClick}
        onClick={props.onClick}
        size={'small'}
      />
    } label={props.label} />
  );
}

export default Grade;