import React from 'react';

const Modal: React.FC<{ closeModal: () => void }> = props => {
  return (
    <>
      <div onClick={props.closeModal} className="backdrop"></div>
      <div className="modalContainer">
        <div className="modal-header">
          <h4 className="modal-title">
            Hotkeys
          </h4>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={props.closeModal}
          >
            <span aria-hidden="true">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body form-group">
          <ul className="list-group main-section">
            <h5 className="list-group-item-heading">
              Exam Carousel:
            </h5>
            <div className="content-border">
              <p className="list-group-item-text list-element">
                '<strong>←</strong>' &nbsp;:&nbsp; Previous Image
              </p>
              <p className="list-group-item-text list-element">
                '<strong>→</strong>' &nbsp;:&nbsp; Next Image
              </p>
              <p className="list-group-item-text list-element">
                Shift+'<strong>←</strong>' &nbsp;:&nbsp; Previous Grade
              </p>
              <p className="list-group-item-text list-element">
                Shift+'<strong>→</strong>' &nbsp;:&nbsp; Next Grade
              </p>
              <p className="list-group-item-text list-element">
                <strong>G</strong> &nbsp;:&nbsp; Toggle Green Plane
              </p>
              <p className="list-group-item-text list-element">
                <strong>Z</strong> &nbsp;:&nbsp; Toggle Zoom
              </p>
              <p className="list-group-item-text list-element">
                <strong>F</strong> &nbsp;:&nbsp; Toggle Fullscreen
              </p>

              <p className="list-group-item-text list-element">
                <strong>A</strong> &nbsp;:&nbsp; Toggle Auto Pagination
              </p>
            </div>
          </ul>
          <ul className="list-group main-section">
            <h5 className="list-group-item-heading">
              Grade Panel:
            </h5>
            <div className="content-border">
              <p className="list-group-item-text list-element">
                <strong>Enter</strong> &nbsp;:&nbsp; Submit Exam
              </p>
              <p className="list-group-item-text list-element">
                <strong>1, 2, 3, 4, 5</strong> &nbsp;:&nbsp; Select Exam Result For Left Eye
              </p>
              <p className="list-group-item-text list-element">
                <strong>6, 7, 8, 9, 0</strong> &nbsp;:&nbsp; Select Exam Result For Right Eye
              </p>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Modal