import GradeAction,
{
  SufficientQualityGradeAction,
  RetinopathyGradeAction,
  MacularEdemaGradeAction,
  NotesFocusAction,
  NotesAction,
  NotesUnfocusAction,
  PreviousGradeAction,
  NextGradeAction,
  SubmitGradeAction,
  ClearGradesAction,
  ProtocolViolationGradeAction,
  GradeSubmitErrorAction,
  AutoPaginationAction
} from './actions';
import { GradeState, initialGradeState } from './state';
import {
  SUFFICIENT_QUALITY_INDEX, RETINOPATHY_INDEX, MACULAR_EDEMA_INDEX, NOTES_INDEX, FIRST_INDEX, LAST_INDEX, PROTOCOL_VIOLATION_INDEX, LEFT_EYE
} from '../../utils/constants';
import { GlobalStateService } from '../../GlobalState.service';
import { convertNumPad } from '../../utils/convertNumPad';

export const reducer = (state: GradeState, action: GradeAction): GradeState => {
  let gradeState: GradeState = state;
  let currentGradeIndex: number;
  let maxVisitableGradeIndex: number;

  if (action instanceof ProtocolViolationGradeAction) {
    if (action.eyeSide === LEFT_EYE) {
      gradeState = {
        ...state,
        leftEye: {
          ...state.leftEye,
          protocolViolationGrade: convertNumPad(action.protocolViolationGrade)
        }
      };
    } else {
      gradeState = {
        ...state,
        rightEye: {
          ...state.rightEye,
          protocolViolationGrade: convertNumPad(action.protocolViolationGrade)
        }
      };
    }

    if (gradeState.autoPagination) {
      if (gradeState.leftEye.protocolViolationGrade !== '' && gradeState.rightEye.protocolViolationGrade !== '') {
        currentGradeIndex = SUFFICIENT_QUALITY_INDEX;
        maxVisitableGradeIndex = SUFFICIENT_QUALITY_INDEX;
      } else {
        currentGradeIndex = PROTOCOL_VIOLATION_INDEX;
        maxVisitableGradeIndex = PROTOCOL_VIOLATION_INDEX;
      }
      gradeState = {
        ...gradeState,
        currentGradeIndex: currentGradeIndex,
        maxVisitableGradeIndex: maxVisitableGradeIndex
      }
    } else {
      gradeState = {
        ...gradeState,
        currentGradeIndex: PROTOCOL_VIOLATION_INDEX,
        maxVisitableGradeIndex: PROTOCOL_VIOLATION_INDEX
      }
    }
  }

  else if (action instanceof SufficientQualityGradeAction) {
    if (action.eyeSide === LEFT_EYE) {
      gradeState = {
        ...state,
        leftEye: {
          ...state.leftEye,
          sufficientQualityGrade: convertNumPad(action.sufficientQualityGrade)
        }
      };
    } else {
      gradeState = {
        ...state,
        rightEye: {
          ...state.rightEye,
          sufficientQualityGrade: convertNumPad(action.sufficientQualityGrade)
        }
      };
    }

    if (gradeState.autoPagination) {
      if (gradeState.leftEye.sufficientQualityGrade !== '' && gradeState.rightEye.sufficientQualityGrade !== '') {
        currentGradeIndex = RETINOPATHY_INDEX;
        maxVisitableGradeIndex = RETINOPATHY_INDEX;
      } else {
        currentGradeIndex = SUFFICIENT_QUALITY_INDEX;
        maxVisitableGradeIndex = SUFFICIENT_QUALITY_INDEX;
      }

      gradeState = {
        ...gradeState,
        currentGradeIndex: currentGradeIndex,
        maxVisitableGradeIndex: maxVisitableGradeIndex
      }
    } else {
      gradeState = {
        ...gradeState,
        currentGradeIndex: SUFFICIENT_QUALITY_INDEX,
        maxVisitableGradeIndex: SUFFICIENT_QUALITY_INDEX
      }
    }
  }

  else if (action instanceof RetinopathyGradeAction) {
    if (action.eyeSide === LEFT_EYE) {
      gradeState = {
        ...state,
        leftEye: {
          ...state.leftEye,
          retinopathyGrade: convertNumPad(action.retinopathyGrade)
        }
      };
    } else {
      gradeState = {
        ...state,
        rightEye: {
          ...state.rightEye,
          retinopathyGrade: convertNumPad(action.retinopathyGrade)
        }
      };
    }
    if (gradeState.autoPagination) {
      if (gradeState.leftEye.retinopathyGrade !== '' && gradeState.rightEye.retinopathyGrade !== '') {
        currentGradeIndex = MACULAR_EDEMA_INDEX;
        maxVisitableGradeIndex = MACULAR_EDEMA_INDEX;
      } else {
        currentGradeIndex = RETINOPATHY_INDEX;
        maxVisitableGradeIndex = RETINOPATHY_INDEX;
      }

      gradeState = {
        ...gradeState,
        currentGradeIndex: currentGradeIndex,
        maxVisitableGradeIndex: maxVisitableGradeIndex
      }
    } else {
      gradeState = {
        ...gradeState,
        currentGradeIndex: RETINOPATHY_INDEX,
        maxVisitableGradeIndex: RETINOPATHY_INDEX
      }
    }
  }

  else if (action instanceof MacularEdemaGradeAction) {
    if (action.eyeSide === LEFT_EYE) {
      gradeState = {
        ...state,
        leftEye: {
          ...state.leftEye,
          macularEdemaGrade: convertNumPad(action.macularEdemaGrade)
        }
      };
    } else {
      gradeState = {
        ...state,
        rightEye: {
          ...state.rightEye,
          macularEdemaGrade: convertNumPad(action.macularEdemaGrade)
        }
      };
    }
    if (gradeState.autoPagination) {
      if (gradeState.leftEye.macularEdemaGrade !== '' && gradeState.rightEye.macularEdemaGrade !== '') {
        currentGradeIndex = NOTES_INDEX;
        maxVisitableGradeIndex = NOTES_INDEX;
      } else {
        currentGradeIndex = MACULAR_EDEMA_INDEX;
        maxVisitableGradeIndex = MACULAR_EDEMA_INDEX;
      }

      gradeState = {
        ...gradeState,
        currentGradeIndex: currentGradeIndex,
        maxVisitableGradeIndex: maxVisitableGradeIndex
      }
    } else {
      gradeState = {
        ...gradeState,
        currentGradeIndex: MACULAR_EDEMA_INDEX,
        maxVisitableGradeIndex: MACULAR_EDEMA_INDEX
      }
    }

  }

  else if (action instanceof NotesFocusAction) {
    gradeState = {
      ...state,
      notesHasFocus: true
    };
  }
  else if (action instanceof NotesAction) {
    gradeState = {
      ...state,
      notes: action.notes
    };
  }
  else if (action instanceof NotesUnfocusAction) {
    gradeState = {
      ...state,
      notesHasFocus: false
    };
  }

  else if (action instanceof PreviousGradeAction) {
    if (state.currentGradeIndex === FIRST_INDEX) {
      currentGradeIndex = PROTOCOL_VIOLATION_INDEX;
    } else {
      currentGradeIndex = state.currentGradeIndex - 1;
    }

    if (state.currentGradeIndex === NOTES_INDEX) {
      currentGradeIndex = MACULAR_EDEMA_INDEX;
    }

    gradeState = {
      ...state,
      currentGradeIndex: currentGradeIndex,
      slideSide: 'right'
    };
  }

  else if (action instanceof NextGradeAction) {
    if (state.currentGradeIndex === LAST_INDEX - 1) {
      currentGradeIndex = NOTES_INDEX;
    } else {
      currentGradeIndex = state.currentGradeIndex + 1;
    }

    if (state.currentGradeIndex === 3) {
      currentGradeIndex = NOTES_INDEX;
    }

    gradeState = {
      ...state,
      currentGradeIndex: currentGradeIndex,
      slideSide: 'left'
    };
  }

  else if (action instanceof SubmitGradeAction) {
    const submittedCorrectly = onSubmit(state);
    gradeState = { ...state, isSubmitting: submittedCorrectly };
  }

  else if (action instanceof GradeSubmitErrorAction) {
    gradeState = { ...state, isSubmitting: false };
  }

  else if (action instanceof ClearGradesAction) {
    gradeState = {
      ...initialGradeState
    };
  }

  else if (action instanceof AutoPaginationAction) {
    gradeState = { ...state, autoPagination: !state.autoPagination };
  }

  else {
    gradeState = state;
  }

  if (state.autoPagination) {
    setTimeout(() => {
      return gradeState;
    }, 1000)
  }

  return gradeState;
};
const onSubmit = (state: GradeState): boolean => {
  if (state.isSubmitting) {
    return true;
  }

  if (!GlobalStateService.getCheckedImages()) {
    GlobalStateService.setAlertType('warning');
    GlobalStateService.alertMessage.next('Cannot submit exam, not all images have been viewed');
    return false;
  }

  // Left Eye
  const protocolViolationLeftEye = state.leftEye.protocolViolationGrade !== '';
  const sufficientQualityLeftEye = state.leftEye.sufficientQualityGrade !== '';
  const retinopathyGradeLeftEye = state.leftEye.retinopathyGrade !== '';
  const macularEdemaGradeLeftEye = state.leftEye.macularEdemaGrade !== '';

  // Right Eye
  const protocolViolationRightEye = state.rightEye.protocolViolationGrade !== '';
  const sufficientQualityRightEye = state.rightEye.sufficientQualityGrade !== '';
  const retinopathyGradeRightEye = state.rightEye.retinopathyGrade !== '';
  const macularEdemaGradeRightEye = state.rightEye.macularEdemaGrade !== '';

  if (!protocolViolationLeftEye
    && sufficientQualityLeftEye
    && retinopathyGradeLeftEye
    && macularEdemaGradeLeftEye
    && protocolViolationRightEye
    && sufficientQualityRightEye
    && retinopathyGradeRightEye
    && macularEdemaGradeRightEye) {
    GlobalStateService.alertMessage.next('Cannot submit exam, required grades have not been entered');
    return false;
  }

  return true;
};