import { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
import { GlobalStateService } from '../GlobalState.service'

const AlertMessage = (props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [alertType, setAlertType] = useState<string>('');
  const [duration, setDuration] = useState<number>(0);

  const onDismiss = () => {
    setVisible(false);
  }

  useEffect(() => {
    if (visible) {
      setTimeout(() => { setVisible(false) }, duration > 0 ? duration : 4000);
    }
  }, [visible, duration])

  useEffect(() => {
    GlobalStateService.alertMessage.subscribe({
      next: () => {
        setVisible(GlobalStateService.alertMessage.value.length > 0);
        setAlertContent(GlobalStateService.alertMessage.value);
        setAlertType(GlobalStateService.getAlertType());
      },
      error: () => console.log('error on showing alert message')
    });

    GlobalStateService.duration.subscribe({
      next: () => {
        setDuration(GlobalStateService.getDuration());
      },
      error: () => console.log('error on setting duration')
    });
  }, [])

  return (
    <div className='alertContainer'>
      <Alert color={alertType} className='alertModal' isOpen={visible} toggle={onDismiss}>
        <span>{alertContent}</span>
      </Alert>
    </div>
  );
}

export default AlertMessage;