import React, { useEffect } from 'react';
import { SubmitGradeAction, ClearGradesAction } from '../actions';
import { body } from '../../../utils/exam-body';
import { GlobalStateService } from '../../../GlobalState.service';
import * as requests from '../../../hooks/useHttp/http-requests';
import useHttp from '../../../hooks/useHttp/useHttp';
import Spinner from '../../../UI/Spinner/spinner.component';

const Submit: React.FC<{ GradeStateActionHandler: (gradeAction: any) => void, active: () => boolean, gradeState: any }> = (props) => {
  const handleClick = () => {
    props.GradeStateActionHandler(new SubmitGradeAction());
  }

  const { loading: gradeLoading, error: gradeError, data: gradeResponse, sendRequest: doGrade } = useHttp(requests.doGrade());
  const { loading: fetchExamLoading, error: fetchExamError, data: fetchExamResponse, sendRequest: fetchExam } = useHttp(requests.fetchExam());


  useEffect(() => {
    if (props.gradeState.isSubmitting) {
      doGrade({ body: body(props.gradeState) })
    }
  }, [props.gradeState.isSubmitting]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gradeResponse === '' && !gradeLoading) {
      fetchExam({
        params: { username: localStorage.getItem('username') }
      });
    }
  }, [gradeResponse, gradeLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchExamResponse && !fetchExamLoading) {
      GlobalStateService.disableAllImagesChecked();
      localStorage['examId'] = fetchExamResponse.examId;
      GlobalStateService.setNumberOfExams(fetchExamResponse.totalNumberOfExams);
      GlobalStateService.setExamName(fetchExamResponse.examName);
      GlobalStateService.setExamImages(fetchExamResponse.images)
      GlobalStateService.setNumberOfImages(fetchExamResponse.imagesCount)

      props.GradeStateActionHandler(new ClearGradesAction());
      GlobalStateService.disableAllImagesChecked();
      GlobalStateService.setAlertType('success');
      GlobalStateService.alertMessage.next('Exam Submitted');
    }
  }, [fetchExamResponse, fetchExamLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchExamError) {
      GlobalStateService.setAlertType('danger');
      GlobalStateService.setAlertMessage(fetchExamError.data);
    }
  }, [fetchExamError])

  useEffect(() => {
    if (gradeError) {
      GlobalStateService.setAlertType('danger');
      GlobalStateService.setAlertMessage(gradeError.data);
    }
  }, [gradeError])

  return (
    <>
      {gradeLoading || fetchExamLoading ? <Spinner /> : null}
      <button
        onClick={handleClick}
        disabled={!props.active()}
      >
        {'Submit'}
      </button>
    </>
  )
}

export default Submit;