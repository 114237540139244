export class ProtocolViolationGradeAction {
  constructor(public protocolViolationGrade: string, public eyeSide: string = '') { }
}

export class SufficientQualityGradeAction {
  constructor(public sufficientQualityGrade: string, public eyeSide: string = '') { }
}

export class RetinopathyGradeAction {
  constructor(public retinopathyGrade: string, public eyeSide: string = '') { }
}

export class MacularEdemaGradeAction {
  constructor(public macularEdemaGrade: string, public eyeSide: string = '') { }
}

export class GlaucomaGradeAction {
  constructor(public glaucomaGrade: string, public eyeSide: string = '') { }
}

export class NotesFocusAction { }

export class NotesAction {
  constructor(public notes: string) { }
}

export class NotesUnfocusAction { }

export class PreviousGradeAction { }

export class NextGradeAction { }

export class AutoPaginationAction { }

export class SubmitGradeAction {}

export class GradeSubmitErrorAction { }

export class ClearGradesAction { }

type GradeAction = ProtocolViolationGradeAction
  | SufficientQualityGradeAction
  | RetinopathyGradeAction
  | MacularEdemaGradeAction
  | GlaucomaGradeAction
  | NotesFocusAction
  | NotesAction
  | NotesUnfocusAction
  | PreviousGradeAction
  | NextGradeAction
  | SubmitGradeAction
  | GradeSubmitErrorAction
  | ClearGradesAction
  | AutoPaginationAction;

export default GradeAction;
