import React, { useState, useEffect } from 'react';
import './exam-grader.styles.css';
import { PROTOCOL_VIOLATION_INDEX, SUFFICIENT_QUALITY_INDEX, RETINOPATHY_INDEX, MACULAR_EDEMA_INDEX, NOTES_INDEX, LEFT_EYE } from '../../../utils/constants';
import SufficientQuality from '../components/sufficient-quality.component';
import Retinopathy from '../components/retinopathy.component';
import MacularEdema from '../components/macular-edema.component';
import Notes from '../components/notes.component';
import ProtocolViolation from '../components/protocol-violation.component';
import ButtonBar from '../components/button-bar.component';

const ExamGrader: React.FC<{
  GradeStateActionHandler: (gradeAction: any) => void,
  eyeSide: string,
  showBtnBar: boolean,
  gradeState: any
}> = (props) => {
  const { currentGradeIndex } = props.gradeState;
  const [visible, setVisible] = useState(0);

  useEffect(() => {
    setTimeout(() => setVisible(currentGradeIndex), props.gradeState.autoPagination ? 300 : 0)
  }, [props.gradeState.autoPagination, currentGradeIndex]);

  return (
    <div className={'container-fluid exam-grader-container'}>
      <div className={'top-element'}>
        <div className={'exam-container'}>
          <div className={'form-group div-animation'} >
            {visible !== NOTES_INDEX && <p className={'examGradeTitle'}>{props.eyeSide === LEFT_EYE ? 'LEFT EYE' : 'RIGHT EYE'}</p>}

            {visible === PROTOCOL_VIOLATION_INDEX &&
              <ProtocolViolation {...props} />
            }
            {visible === SUFFICIENT_QUALITY_INDEX &&
              <SufficientQuality {...props} />
            }
            {visible === RETINOPATHY_INDEX &&
              <Retinopathy {...props} />
            }
            {visible === MACULAR_EDEMA_INDEX &&
              <MacularEdema {...props} />
            }
            {visible === NOTES_INDEX && props.showBtnBar
              ? <Notes {...props} />
              : null
            }
          </div>
          {props.showBtnBar && <ButtonBar {...props} />}
        </div>
      </div>
    </div>
  )
}

export default ExamGrader;
