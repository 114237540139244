import React, { useEffect, useState } from 'react';
import { GlobalStateService } from '../../GlobalState.service';

const IdleModal: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    GlobalStateService.idleModal.subscribe({
      next: () => {
        setShowModal(GlobalStateService.getIdleModal());
      },
      error: () => console.log('error')
    });
  }, [])

  const idleHandler = () => {
    GlobalStateService.closeIdleModal();
  }

  return (
    <>
      {
        showModal &&
        <>
          <div className="backdrop"></div>
          <div className="modalContainer">
            <div className="modal-header">
              <h4 className="modal-title">
                You Have Been Idle!
              </h4>
            </div>
            <div className="modal-body form-group">
              <p>Your session has expired, please login again.</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={idleHandler}
              >
                Close
              </button>              
            </div>
          </div>
        </>
      }
    </>
  )
}

export default IdleModal;
