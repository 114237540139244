import React, { useEffect } from 'react';
import Grade from './grade.component';
import RadioGroup from '@material-ui/core/RadioGroup';
import { NO_PROTOCOL_VIOLATION, PROTOCOL_VIOLATION, LEFT_EYE } from '../../../utils/constants';
import { ProtocolViolationGradeAction } from '../actions';
import hotkeys from 'hotkeys-js';
import Slide from "@material-ui/core/Slide";

const ProtocolViolation: React.FC<{
  GradeStateActionHandler: (gradeAction: any) => void,
  eyeSide: string,
  showBtnBar: boolean,
  gradeState: any
}> = (props) => {
  if (props.eyeSide === LEFT_EYE) {
    hotkeys('1,2,num_1,num_2', 'protocolViolation', function (event, handler) {
      props.GradeStateActionHandler(new ProtocolViolationGradeAction(handler.key, props.eyeSide))
    });
  } else {
    hotkeys('6,7,num_6,num_7', 'protocolViolation', function (event, handler) {
      props.GradeStateActionHandler(new ProtocolViolationGradeAction(handler.key, props.eyeSide))
    });
  }

  useEffect(() => {
    hotkeys.setScope('protocolViolation');
  }, []);

  useEffect(() => {
    return () => {
      hotkeys.deleteScope('protocolViolation');
    }
  }, []);

  const { protocolViolationGrade } = props.gradeState[props.eyeSide];

  const handleClick = (event: any): void => {
    const grade = (event.target as HTMLInputElement).value;
    props.GradeStateActionHandler(new ProtocolViolationGradeAction(grade, props.eyeSide))
  }

  const args = {
    name: `${props.eyeSide}ProtocolViolationGrade`,
    onClick: handleClick
  };

  const noProtocolViolation = protocolViolationGrade === '1' || protocolViolationGrade === '6';
  const protocolViolation = protocolViolationGrade === '2' || protocolViolationGrade === '7';

  return (
    <Slide direction={props.gradeState.slideSide} in={true} mountOnEnter unmountOnExit timeout={300} >
      <div>
        <p className='examTitle'>{'Protocol Violation'}</p>
        <RadioGroup row aria-label="position" name={`${props.eyeSide}position`} defaultValue="top">
          <Grade
            value={NO_PROTOCOL_VIOLATION}
            label={'No Protocol Violation'}
            checked={noProtocolViolation}
            {...args}
          />

          <Grade
            value={PROTOCOL_VIOLATION}
            label={'Protocol Violation'}
            checked={protocolViolation}
            {...args}
          />
        </RadioGroup>
      </div>
    </Slide>
  )
}

export default ProtocolViolation;