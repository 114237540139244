import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { GlobalStateService } from '../../GlobalState.service';
import CarouselContainer from '../carousel/carousel.component'
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import './exam-viewer.styles.css';

const ExamViewer: React.FC = () => {
  const [IsZoom, setZoom] = useState<boolean>(false);
  const [isGreyScale, setGreyScale] = useState<boolean>(false)
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const zoomHandler = () => {
    setZoom(prevState => !prevState)
  }

  const fullScreenHandler = () => {
    GlobalStateService.setFullscreen();
  }

  const greyScaleHandler = () => {
    setGreyScale(prevState => !prevState);
  }

  const showHotkeysModalHandler = () => {
    GlobalStateService.setShowHotkeysModal();
  }

  useEffect(() => {
    GlobalStateService.fullscreen.subscribe({
      next: () => {
        setFullScreen(GlobalStateService.getFullscreen());
      },
      error: () => console.log('error on showing fullscreen')
    });
  }, [])

  return (
    <div className='examViewerTools'>
      <div className="tolls">
        <div className="tollsContainer">
          <div className="separators">
            <div className="separator">
              <Tooltip title="Green Plane">
                <FormControlLabel
                  control={
                    <Switch
                      size='medium'
                      checked={isGreyScale}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      onChange={greyScaleHandler} />
                  }
                  label="Green Plane" />
              </Tooltip>
            </div>
            <div className="separator">
              <Tooltip title="Zoom">
                <FormControlLabel
                  control={
                    <Switch
                      checked={IsZoom}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      onChange={zoomHandler} />
                  }
                  label="Zoom" />
              </Tooltip>
            </div>
            <div className="separator">
              <Tooltip title="FullScreen">
                <FormControlLabel
                  control={
                    <Switch
                      checked={fullScreen}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      onChange={fullScreenHandler} />
                  }
                  label="FullScreen" />
              </Tooltip>
            </div>
          </div>
          <Button onClick={showHotkeysModalHandler} variant="outlined">Hotkeys</Button>
        </div>
      </div>
      <CarouselContainer
        handleFullScreen={fullScreenHandler}
        zoomed={IsZoom}
        handleZoom={zoomHandler}
        grayScale={isGreyScale}
        grayHandler={greyScaleHandler}
      />
    </div>
  )
}

export default ExamViewer;
