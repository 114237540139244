import { useEffect, useState } from 'react';
import * as requests from '../../hooks/useHttp/http-requests';
import useHttp from '../../hooks/useHttp/useHttp';
import { GlobalStateService } from '../../GlobalState.service';
import Spinner from '../../UI/Spinner/spinner.component';
import './login.styles.css';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/images/logo.png';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import axios from 'axios';

const useStyles = makeStyles(() => ({
  Input: {
    marginTop: '20px'
  },
  Button: {
    width: '150px',
    marginBottom: '20px',
    '@media(max-width: 850px)': {
      margin: '20px auto',
      display: 'block'
    }
  }
}))


const Login: React.FC<{ userStatus: () => void, history: any }> = (props) => {
  const location = useLocation()
  const classes = useStyles();

  const [cognitoLoading, setCognitoLoading] = useState(false);

  const { loading, error, data, sendRequest: getUserInfo } = useHttp(requests.userInfo());

  const formSubmitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    const cognitoHostedUi = process.env.REACT_APP_COGNITO_HOSTED_UI as string;
    window.location.replace(cognitoHostedUi + '&state=' + location.pathname);
  }

  useEffect(() => {
    if (error) {
      GlobalStateService.setAlertType('danger');
      GlobalStateService.setAlertMessage(error.data.message);
    }
  }, [error])

  useEffect(() => {
    const params = queryString.parse(location.search);

    if (params.code) {
      setCognitoLoading(true);
      const redirect_uri = window.location.origin
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: {
          grant_type: 'authorization_code',
          code: params.code,
          client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
          redirect_uri
        }
      }
      
      axios.post(process.env.REACT_APP_TOKEN_ENDPOINT as string, null, config)
        .then((response) => {
          if(response){
            localStorage['idxCemToken'] = response.data.access_token;
            localStorage['idxCemRefreshToken'] = response.data.access_token;
            getUserInfo();
          }
        })
        .catch((error) => {
          setCognitoLoading(false)
          if (error) {
            GlobalStateService.setAlertType('danger');
            GlobalStateService.setAlertMessage(error.data.message);
          }
        })
    }

    if (params.state) {
      const page = params.state as string;
      props.history.push(page);      
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(data){
      localStorage['username'] = data.email;
      setCognitoLoading(false)
      props.userStatus();
    }
  }, [data])

  return (
    <>
      {(loading && cognitoLoading) && <Spinner />}
      <div className="login">
        <div className="logo-dxs">
          <img src={logo} alt="digital-diagnostics" />
        </div>
        <form className="form">
          <Button
            variant="contained"
            color="primary"
            onClick={formSubmitHandler}
            className={`${classes.Input} ${classes.Button}`}
          >login
          </Button>
        </form>
      </div>
    </>
  )
}

export default withRouter(Login);