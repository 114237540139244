import React, { useEffect } from 'react';
import { LEFT_EYE } from '../../../utils/constants';
import { SufficientQualityGradeAction } from '../actions';
import Grade from './grade.component';
import RadioGroup from '@material-ui/core/RadioGroup';
import hotkeys from 'hotkeys-js';
import Slide from "@material-ui/core/Slide";

const SufficientQuality: React.FC<{
  GradeStateActionHandler: (gradeAction: any) => void,
  eyeSide: string,
  showBtnBar: boolean,
  gradeState: any
}> = (props) => {
  if (props.eyeSide === LEFT_EYE) {
    hotkeys('1,2,num_1,num_2', 'SufficientQualityGrade', function (event, handler) {
      props.GradeStateActionHandler(new SufficientQualityGradeAction(handler.key, props.eyeSide))
    });
  } else {
    hotkeys('6,7,num_6,num_7', 'SufficientQualityGrade', function (event, handler) {
      props.GradeStateActionHandler(new SufficientQualityGradeAction(handler.key, props.eyeSide))
    });
  }

  useEffect(() => {
    hotkeys.setScope('SufficientQualityGrade');
  }, []);

  useEffect(() => {
    return () => {
      hotkeys.deleteScope('SufficientQualityGrade');
    }
  }, []);

  const { sufficientQualityGrade } = props.gradeState[props.eyeSide];

  const handleClick = (event: any): void => {
    const grade = (event.target as HTMLInputElement).value;
    props.GradeStateActionHandler(new SufficientQualityGradeAction(grade, props.eyeSide))
  }

  const args = {
    name: `${props.eyeSide}SufficientQualityGrade`,
    onClick: handleClick
  };

  const noSufficientQualityGrade = sufficientQualityGrade === '1' || sufficientQualityGrade === '6';
  const sufficientQuality = sufficientQualityGrade === '2' || sufficientQualityGrade === '7';

  return (
    <Slide direction={props.gradeState.slideSide} in={true} mountOnEnter unmountOnExit timeout={300}>
      <div>
        <p className='examTitle'>{'Image Quality'}</p>
        <RadioGroup row aria-label="position" name={`${props.eyeSide}position`} defaultValue="top">
          <Grade
            value={'1'}
            label={'Sufficient Image Quality'}
            checked={noSufficientQualityGrade}
            {...args}
          />

          <Grade
            value={'2'}
            label={'Insufficient Image Quality'}
            checked={sufficientQuality}
            {...args}
          />
        </RadioGroup>
      </div>
    </Slide>
  )
}

export default SufficientQuality;