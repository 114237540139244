import React from 'react';
import {PreviousGradeAction} from '../actions';
import hotkeys from 'hotkeys-js';

const Previous: React.FC<{GradeStateActionHandler: (gradeAction: any) => void, active: boolean}> = (props) => {
  hotkeys('shift+left', function (event, handler) {
    switch (handler.key) {
      case 'shift+left': handleClick();
    }
  });
  const handleClick = () => {
    props.GradeStateActionHandler(new PreviousGradeAction());
  }
  return (
    <button
      onClick={handleClick}
      disabled={!props.active}
    >
      {'⮜ Previous'}
    </button>
  )
}

export default Previous;