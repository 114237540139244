import { useState, useEffect } from 'react';
import './App.css';
import Login from './components/login/login.component';
import Navbar from './components/navbar/navbar.component';
import AlertMessage from './UI/alertMessage.component';
import MainContent from './components/main-content/main-content.component';
import HotkeysModal from './UI/hotkeysModal/hotkeys-modal.component';
import IdleTimer from 'react-idle-timer';
import { GlobalStateService } from './GlobalState.service';
import IdleModal from './UI/idle-modal/idle.component';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  const [isLoggedUser, setIsLoggedUser] = useState<boolean>(false);

  const setStatusForUser = () => {
    setIsLoggedUser(localStorage.getItem('username') !== null);
  }

  useEffect(() => {
    setIsLoggedUser(localStorage.getItem('username') !== null);
  }, [])

  const LogoutUserHandler = () => {
    localStorage.clear();
    setIsLoggedUser(false);
  }

  const handleOnIdle = () => {
    if (isLoggedUser) {
      GlobalStateService.setIdleModal();
      LogoutUserHandler();
    }
  }

  return (
    <BrowserRouter>
      <div className="container-fluid root-container main-content-wrapper">
        <IdleTimer
          timeout={3600000}
          onIdle={handleOnIdle}
          debounce={250}
        />
        {isLoggedUser && <Navbar userLogout={LogoutUserHandler} isLoggedUser={isLoggedUser} />}
        {!isLoggedUser ? <Login userStatus={setStatusForUser} /> : <MainContent />}
        <AlertMessage />
        <HotkeysModal />
        <IdleModal />
      </div>
    </BrowserRouter>
  );
}

export default App;
