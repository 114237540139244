export interface GradeState {
  leftEye: {
    sufficientQualityGrade: string;
    protocolViolationGrade: string;
    retinopathyGrade: string;
    macularEdemaGrade: string;
    glaucomaGrade: string;
  };
  rightEye: {
    sufficientQualityGrade: string;
    protocolViolationGrade: string;
    retinopathyGrade: string;
    macularEdemaGrade: string;
    glaucomaGrade: string;
  };
  notes: string;
  notesHasFocus: boolean;
  currentGradeIndex: number;
  maxVisitableGradeIndex: number;
  isSubmitting: boolean;
  autoPagination: boolean;
  slideSide: string;
}

export const initialGradeState: GradeState = {
  leftEye: {
    sufficientQualityGrade: '',
    protocolViolationGrade: '',
    retinopathyGrade: '',
    macularEdemaGrade: '',
    glaucomaGrade: ''
  },
  rightEye: {
    sufficientQualityGrade: '',
    protocolViolationGrade: '',
    retinopathyGrade: '',
    macularEdemaGrade: '',
    glaucomaGrade: ''
  },
  notes: '',
  notesHasFocus: false,
  currentGradeIndex: 0,
  maxVisitableGradeIndex: 0,
  isSubmitting: false,
  autoPagination: false,
  slideSide: 'left'
};
