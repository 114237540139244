import './spinner.styles.css';

const Spinner = () => {
  return (
    <>
      <div className="SpinnerBackground"></div>
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </>

  );
}

export default Spinner;