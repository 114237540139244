import React, { useState, useEffect } from 'react';
import ExamViewer from '../exam-viewer/exam-viewer.component';
import { GlobalStateService } from '../../GlobalState.service'
import ExamGradingContainer from '../exam-grader/exam-grading-container/exam-grading-container.component';
import * as requests from '../../hooks/useHttp/http-requests';
import useHttp from '../../hooks/useHttp/useHttp';
import Spinner from '../../UI/Spinner/spinner.component';

import './main-content.styles.css';

const MainContent: React.FC<{}> = () => {
  const [isFullScreen, setFullScreen] = useState<boolean>(false);
  const [examName, setExamName] = useState<string>('');
  const [totalNumberOfExams, setTotalNumberOfExams] = useState<number>(0);

  const { loading: fetchExamLoading, error: fetchExamError, data: fetchExamData, sendRequest: fetchExam } = useHttp(requests.fetchExam());

  useEffect(() => {
    GlobalStateService.fullscreen.subscribe({
      next: () => {
        setFullScreen(GlobalStateService.getFullscreen());
      },
      error: () => console.log('error on showing fullscreen')
    });

    GlobalStateService.examName.subscribe({
      next: () => {
        setExamName(GlobalStateService.getExamName());
      },
      error: () => console.log('error on setting exam name')
    });

    GlobalStateService.numberOfExams.subscribe({
      next: () => {
        setTotalNumberOfExams(GlobalStateService.getNumberOfExams());
      },
      error: () => console.log('error on setting number of exams')
    });
  }, [])

  useEffect(() => {
    fetchExam({
      params: { username: localStorage.getItem('username') }
    });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchExamError) {
      GlobalStateService.setAlertType('danger');
      GlobalStateService.setAlertMessage(fetchExamError.data);
    }
  }, [fetchExamError])

  useEffect(() => {
    if (fetchExamData) {
      localStorage['examId'] = fetchExamData.examId;
      GlobalStateService.setNumberOfExams(fetchExamData.totalNumberOfExams);
      GlobalStateService.setExamName(fetchExamData.examName);
      GlobalStateService.setExamImages(fetchExamData.images)
      GlobalStateService.setNumberOfImages(fetchExamData.imagesCount)
    }
  }, [fetchExamData])

  return (
    <>
      {fetchExamLoading && <Spinner />}
      <div className="container-fluid">
        <div className="row main-content-row">
          <div id="examContentDiv" className={!isFullScreen ? 'col-xl-9 col-lg-8 col-sm-7' : ''}>
            {examName !== '' && examName !== null && <ExamViewer />}
          </div>
          {
            !isFullScreen &&
            <div className="col-xl-3 col-lg-4 col-sm-5 no-horizontal-padding">
              <div className="root-container">
                <div>
                  {
                    totalNumberOfExams > 0 &&
                    <div>
                      <h4 className="needs-grading-header">
                        {totalNumberOfExams} Exams Left to Grade
                      </h4>
                      <hr className="needs-grading-underline" />
                    </div>
                  }
                </div>
                <ExamGradingContainer />
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default MainContent;