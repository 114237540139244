import React, { useState, useEffect } from 'react';
import { Carousel } from "react-responsive-carousel";
import * as Zoom from "react-img-zoom";
import hotkeys from 'hotkeys-js';
import { GlobalStateService } from '../../GlobalState.service';
import SmallLoader from '../../UI/small-loader/small-loader.component';

import './carousel.styles.css';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const CarouselContainer: React.FC<{
  handleFullScreen: () => void,
  zoomed: boolean,
  handleZoom: () => void,
  grayScale: boolean;
  grayHandler: () => void
}> = (props) => {

  const { width } = useWindowDimensions();
  hotkeys('z,g,f', function (event, handler) {
    switch (handler.key) {
      case 'z': props.handleZoom();
        break;
      case 'g': props.grayHandler();
        break;
      case 'f': props.handleFullScreen();
        break;
    }
  });

  const [allImagesArray, setAllImagesArray] = useState<any>([])
  const [check, setCheck] = useState(false);
  const [examImages, setExamImages] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [imagesCount, setImagesCount] = useState<number>(0);

  const checkAllImagesHandler = (e) => {
    setAllImagesArray(oldArray => [...oldArray, e]);
  }

  useEffect(() => {
    const arrayImg = imagesCount === 4 ? [1, 2, 3] : [1];
    setCheck(arrayImg.every(i => allImagesArray.includes(i)));
  }, [allImagesArray, imagesCount])

  useEffect(() => {
    if (check) {
      GlobalStateService.setCheckedImages(true);
    } else {
      GlobalStateService.disableAllImagesChecked();
      setAllImagesArray([]);
    }
  }, [check])

  useEffect(() => {
    GlobalStateService.examImages.subscribe({
      next: () => {
        setLoader(true);
        setExamImages(GlobalStateService.getExamImages());
      },
      error: () => console.log('error on setting exam images')
    });

    GlobalStateService.numberOfImages.subscribe({
      next: () => {
        setImagesCount(GlobalStateService.getNumberOfImages());
      },
      error: () => console.log('error on setting number of images')
    });

    GlobalStateService.checkedImages.subscribe({
      next: () => {
        setCheck(GlobalStateService.getCheckedImages());
      },
      error: () => console.log('error on setting checked images flag')
    });

  }, [])

  useEffect(() => {
    if (examImages) {
      setLoader(false);
    }
  }, [examImages])

  return (
    <>
      {
        loader ? <SmallLoader />
          : <div className='carousel'>
            <Carousel
              axis='horizontal'
              dynamicHeight
              showThumbs={false}
              useKeyboardArrows={true}
              showArrows
              infiniteLoop
              centerSlidePercentage={100}
              className={props.grayScale ? 'grayScale' : ''}
              onChange={(e) => checkAllImagesHandler(e)}
            >
              {
                examImages?.map((image, index) =>
                  <div key={index} className='zoomBlock'>
                    <Zoom
                      img={image.url}
                      zoomScale={props.zoomed ? 3 : 1}
                      height={550}
                      width={0.6 * width}
                      transitionTime={0.3}
                    />
                  </div>
                )
              }
            </Carousel>
          </div>
      }
    </>
  );
}

export default CarouselContainer;