import axios from '../axios'
import { useCallback, useReducer } from 'react'

const HTTP_START = 'HTTP_START'
const HTTP_SUCCESS = 'HTTP_SUCCESS'
const HTTP_FAIL = 'HTTP_FAIL'
const ADD_ITEM = 'ADD_ITEM'
const REMOVE_ITEM = 'REMOVE_ITEM'
const UPDATE_ITEM = 'UPDATE_ITEM'

export interface IInitialState {
  loading: boolean;
  error: any;
  data: any,
  args: any
}

const initialState: IInitialState = {
  loading: false,
  error: null,
  data: null,
  args: null
}

const reducer = (state = initialState, action:any) => {
  switch (action.type) {
    case HTTP_START:
      return { ...state, loading: true, args: action.payload }
    case HTTP_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case HTTP_FAIL:
      return { ...state, loading: false, error: action.payload }
    case ADD_ITEM:
      return { ...state, data: state.data?.concat(action.payload) }
    case REMOVE_ITEM:
      return { ...state, data: state.data.filter((el:any) => el.id !== action.payload) }
    case UPDATE_ITEM:
      return { ...state, data: state.data.map((el:any) => el.id === action.id ? { id: action.id, ...action.payload } : el) }
    default:
      return state
  }
}

const useFetch = (request:any) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const sendRequest = useCallback((args = {}) => {
    const transformedRequest = transformRequest(request, args)

    dispatch({ type: HTTP_START, payload: args });
    axios({
      ...transformedRequest,
      data: args.body,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('idxCemToken')
      }
    })
      .then(response => {
        dispatch({ type: HTTP_SUCCESS, payload: response.data })
        return null
      })
      .catch(error => {
        dispatch({ type: HTTP_FAIL, payload: error.response ?? { data: { message: 'Connection error' } } })
      })
  }, [request])

  const addItem = useCallback((item) => {
    dispatch({ type: ADD_ITEM, payload: item })
  }, [])

  const removeItem = useCallback((id) => {
    dispatch({ type: REMOVE_ITEM, payload: id })
  }, [])

  const updateItem = useCallback((id, item) => {
    dispatch({ type: UPDATE_ITEM, payload: item, id })
  }, [])

  return {
    ...state,
    sendRequest,
    addItem,
    removeItem,
    updateItem
  }
}

const transformRequest = (request:any, args:any) => {
  const transformedRequest = { ...request }
  if (transformedRequest.url && args && args.params) {
    for (const param in args.params) {
      transformedRequest.url = transformedRequest.url.replace(`:${param}`, args.params[param])
    }
  }
  if (transformedRequest.url && args && args.query) {
    const queryArray:any = []
    for (const param in args.query) {
      queryArray.push(`${param}=${args.query[param]}`)
    }
    transformedRequest.url = `${transformedRequest.url}?${queryArray.join('&')}`
  }
  return transformedRequest
}

export default useFetch;
