export const convertNumPad = (number) => {
  let convertedValue = number;
  switch (number) {
    case 'num_1':
      convertedValue = '1';
      break;
    case 'num_2':
      convertedValue = '2';
      break;
    case 'num_3':
      convertedValue = '3';
      break;
    case 'num_4':
      convertedValue = '4';
      break;
    case 'num_5':
      convertedValue = '5';
      break;
    case 'num_6':
      convertedValue = '6';
      break;
    case 'num_7':
      convertedValue = '7';
      break;
    case 'num_8':
      convertedValue = '8';
      break;
    case 'num_9':
      convertedValue = '9';
      break;
    case 'num_0':
      convertedValue = '0';
      break;
    default:
      convertedValue = number;
      break;
  }
  return convertedValue;
}
