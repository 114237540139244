import React, { useEffect, useState } from 'react';
import { GlobalStateService } from '../../GlobalState.service';
import Modal from './modal.component';
import hotkeys from 'hotkeys-js';

import './hotkeys-modal.styles.css';

const HotkeysModal: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    GlobalStateService.showHotkeysModal.subscribe({
      next: () => {
        setShowModal(GlobalStateService.getShowHotkeysModal());
      },
      error: () => console.log('error on showing hotkeys modal')
    });
  }, [])

  const closeModalHandler = () => {
    GlobalStateService.closeShowHotkeysModal();
  }

  hotkeys('esc', function (event, handler) {
    showModal && GlobalStateService.closeShowHotkeysModal();
  });
  
  return (
    <>
      {
        showModal ? <Modal closeModal={closeModalHandler} /> : null
      }
    </>
  )
}

export default HotkeysModal;
