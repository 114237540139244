import React, { useEffect } from 'react';
import { LEFT_EYE } from '../../../utils/constants';
import { RetinopathyGradeAction } from '../actions';
import Grade from './grade.component';
import RadioGroup from '@material-ui/core/RadioGroup';
import hotkeys from 'hotkeys-js';
import Slide from "@material-ui/core/Slide";

const Retinopathy: React.FC<{
  GradeStateActionHandler: (gradeAction: any) => void,
  eyeSide: string,
  showBtnBar: boolean,
  gradeState: any
}> = (props) => {
  const { retinopathyGrade } = props.gradeState[props.eyeSide];

  const handleClick = (event: any): void => {
    const grade = (event.target as HTMLInputElement).value;
    props.GradeStateActionHandler(new RetinopathyGradeAction(grade, props.eyeSide))
  }

  const args = {
    name: `${props.eyeSide}RetinopathyGrade`,
    onClick: handleClick
  };

  if (props.eyeSide === LEFT_EYE) {
    hotkeys('1,2,3,4,5,num_1,num_2,num_3,num_4,num_5', 'Retinopathy', function (event, handler) {
      props.GradeStateActionHandler(new RetinopathyGradeAction(handler.key, props.eyeSide))
    });
  } else {
    hotkeys('6,7,8,9,0,num_6,num_7,num_8,num_9,num_0', 'Retinopathy', function (event, handler) {
      props.GradeStateActionHandler(new RetinopathyGradeAction(handler.key, props.eyeSide))
    });
  }

  useEffect(() => {
    hotkeys.setScope('Retinopathy');
  }, []);

  useEffect(() => {
    return () => {
      hotkeys.deleteScope('Retinopathy');
    }
  }, []);

  const noApparentRetinopathy = retinopathyGrade === '1' || retinopathyGrade === '6';
  const mildRetinopathy = retinopathyGrade === '2' || retinopathyGrade === '7';
  const moderateRetinopathy = retinopathyGrade === '3' || retinopathyGrade === '8';
  const severeRetinopathy = retinopathyGrade === '4' || retinopathyGrade === '9';
  const proliferativeRetinopathy = retinopathyGrade === '5' || retinopathyGrade === '0';
  
  return (
    <Slide direction={props.gradeState.slideSide} in={true} mountOnEnter unmountOnExit timeout={300} >
      <div>
        <p className='examTitle'>{'Retinopathy'}</p>
        <RadioGroup row aria-label="position" name={`${props.eyeSide}position`} defaultValue="top">
          <Grade
            value={'1'}
            label={'No Apparent Retinopathy'}
            {...args}
            checked={noApparentRetinopathy}
          />
          <Grade
            value={'2'}
            label={'Mild Non-Proliferative Diabetic Retinopathy'}
            {...args}
            checked={mildRetinopathy}
          />
          <Grade
            value={'3'}
            label={'Moderate Non-Proliferative Diabetic Retinopathy'}
            {...args}
            checked={moderateRetinopathy}
          />
          <Grade
            value={'4'}
            label={'Severe Non-Proliferative Diabetic Retinopathy'}
            {...args}
            checked={severeRetinopathy}
          />
          <Grade
            value={'5'}
            label={'Proliferative Diabetic Retinopathy'}
            {...args}
            checked={proliferativeRetinopathy}
          />
        </RadioGroup>
      </div>
    </Slide>
  )
}

export default Retinopathy;