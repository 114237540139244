import { GradeState } from '../components/exam-grader/state'
import { PROTOCOL_VIOLATION, INSUFFICIENT_QUALITY, PRESENT } from './constants';
const retinopathyGradeHotKeys = (retinopathy: string) => {
  let retinopathyConvert: string;
  switch (retinopathy) {
    case '6':
      retinopathyConvert = '1';
      break;
    case '7':
      retinopathyConvert = '2';
      break;
    case '8':
      retinopathyConvert = '3';
      break;
    case '9':
      retinopathyConvert = '4';
      break;
    case '0':
      retinopathyConvert = '5';
      break;
    default:
      retinopathyConvert = retinopathy;
      break;
  }
  return retinopathyConvert;
}

export const body = (state: GradeState) => {
  return {
    username: localStorage.getItem('username'),
    examId: localStorage.getItem('examId'),
    notes: state.notes,
    rightEyeAnalysisPostModel: {
      protocolViolationGrade: state.rightEye.protocolViolationGrade === PROTOCOL_VIOLATION || state.rightEye.protocolViolationGrade === '7',
      imageQualityGrade: state.rightEye.sufficientQualityGrade === INSUFFICIENT_QUALITY || state.rightEye.sufficientQualityGrade === '7',
      retinopathyGrade: parseInt(retinopathyGradeHotKeys(state.rightEye.retinopathyGrade)),
      macularEdemaGrade: state.rightEye.macularEdemaGrade === PRESENT || state.rightEye.macularEdemaGrade === '7',
    },
    leftEyeAnalysisPostModel: {
      protocolViolationGrade: state.leftEye.protocolViolationGrade === PROTOCOL_VIOLATION || state.leftEye.protocolViolationGrade === '7',
      imageQualityGrade: state.leftEye.sufficientQualityGrade === INSUFFICIENT_QUALITY || state.leftEye.sufficientQualityGrade === '7',
      retinopathyGrade: parseInt(retinopathyGradeHotKeys(state.leftEye.retinopathyGrade)),
      macularEdemaGrade: state.leftEye.macularEdemaGrade === PRESENT || state.leftEye.macularEdemaGrade === '7',
    }
  }
};