import React, { useState, useEffect } from 'react';
import './exam-grading-container.styles.css';
import { GlobalStateService } from '../../../GlobalState.service';
import { LEFT_EYE, RIGHT_EYE } from '../../../utils/constants';
import { initialGradeState, GradeState } from '../state';
import GradeAction, { AutoPaginationAction } from '../actions';
import { reducer } from '../reducer';
import ExamGrader from '../exam-grader/exam-grader.component';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import hotkeys from 'hotkeys-js';
import InfoIcon from '@material-ui/icons/Info';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  toolbarCustom: {
    margin: 0
  },
  button: {
    padding: 3,
    color: '#fff'
  }
}))

const ExamGradingContainer: React.FC = () => {
  const classes = useStyles()
  const [numberOfExams, setNumberOfExams] = useState<any>(null);
  const [gradeState, setGradeState] = useState<GradeState>(initialGradeState);
  const [isCheckedAllImages, setIsCheckedAllImages] = useState<boolean>(false)

  const GradeStateActionHandler = (action: GradeAction) => {
    const newState = reducer(JSON.parse(localStorage.getItem('gradingState') || '{}'), action)
    localStorage.setItem('gradingState', JSON.stringify(newState));
    setGradeState(newState)
  }

  useEffect(() => {
    localStorage.setItem('gradingState', JSON.stringify(initialGradeState));
    GlobalStateService.numberOfExams.subscribe({
      next: () => {
        setNumberOfExams(GlobalStateService.getNumberOfExams());
      },
      error: () => console.log('error on getting number of exams')
    });

    GlobalStateService.checkedImages.subscribe({
      next: () => {
        setIsCheckedAllImages(GlobalStateService.getCheckedImages());
      },
      error: () => console.log('error on checking images')
    });
  }, [])

  const { autoPagination } = gradeState;
  hotkeys('a', function () {
    GradeStateActionHandler(new AutoPaginationAction())
  });

  return (
    <div className='examGraderContainer'>
      {
        numberOfExams > 0 ?
          <div className="exam-grader-wrapper">
            <ExamGrader GradeStateActionHandler={GradeStateActionHandler} eyeSide={LEFT_EYE} showBtnBar={false} gradeState={gradeState} />
            <ExamGrader GradeStateActionHandler={GradeStateActionHandler} eyeSide={RIGHT_EYE} showBtnBar gradeState={gradeState} />
            <div className="autoPaginationPanel">
              <Tooltip title="Enable Auto Pagination" className={classes.toolbarCustom}>
                <FormControlLabel
                  control={
                    <Switch
                      size='small'
                      checked={autoPagination}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      onChange={() => GradeStateActionHandler(new AutoPaginationAction())}
                    />
                  }
                  label={
                    <Box component="div" fontSize={14}>
                      Auto Pagination
                    </Box>
                  } />
              </Tooltip>

              <div className="isCheckedContainer">
                {
                  !isCheckedAllImages ?
                    <div className="flagBlock warning">
                      <IconButton className={classes.button}>
                        <Tooltip title="You must check all the images before submitting exam"><InfoIcon /></Tooltip>
                      </IconButton>
                      <span className="checkText">You must check all the images</span>
                    </div>
                    : <div className="flagBlock">
                      <IconButton className={classes.button}>
                        <Tooltip title="All images have been viewed"><DoneAllIcon /></Tooltip>
                      </IconButton>
                      <span className="checkText">All images have been viewed</span>
                    </div>
                }
              </div>
            </div>
          </div>
          : <p className='examsNumber'>No exams to grade</p>
      }
    </div>
  );
}

export default ExamGradingContainer;