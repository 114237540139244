import React, { useEffect } from 'react';
import { LEFT_EYE } from '../../../utils/constants';
import { MacularEdemaGradeAction } from '../actions';
import Grade from './grade.component';
import RadioGroup from '@material-ui/core/RadioGroup';
import hotkeys from 'hotkeys-js';
import Slide from "@material-ui/core/Slide";

const MacularEdema: React.FC<{
  GradeStateActionHandler: (gradeAction: any) => void,
  eyeSide: string,
  showBtnBar: boolean,
  gradeState: any
}> = (props) => {
  const { macularEdemaGrade } = props.gradeState[props.eyeSide];

  const handleClick = (event: any): void => {
    const grade = (event.target as HTMLInputElement).value;
    props.GradeStateActionHandler(new MacularEdemaGradeAction(grade, props.eyeSide))
  }
  const args = {
    name: `${props.eyeSide}SufficientQualityGrade`,
    onClick: handleClick
  };

  if (props.eyeSide === LEFT_EYE) {
    hotkeys('1,2,num_1,num_2', 'MacularEdema', function (event, handler) {
      props.GradeStateActionHandler(new MacularEdemaGradeAction(handler.key, props.eyeSide))
    });
  } else {
    hotkeys('6,7,num_6,num_7', 'MacularEdema', function (event, handler) {
      props.GradeStateActionHandler(new MacularEdemaGradeAction(handler.key, props.eyeSide))
    });
  }

  useEffect(() => {
    hotkeys.setScope('MacularEdema');
  }, []);

  useEffect(() => {
    return () => {
      hotkeys.deleteScope('MacularEdema');
    }
  }, []);
  
  const noMacularEdema = macularEdemaGrade === '1' || macularEdemaGrade === '6';
  const macularEdema = macularEdemaGrade === '2' || macularEdemaGrade === '7';
  
  return (
    <Slide direction={props.gradeState.slideSide} in={true} mountOnEnter unmountOnExit timeout={300} >
      <div>
        <p className='examTitle'>{'Macular Edema'}</p>
        <RadioGroup row aria-label="position" name={`${props.eyeSide}position`} defaultValue="top">
          <Grade
            value={'1'}
            label={'Absent'}
            {...args}
            checked={noMacularEdema}
          />

          <Grade
            value={'2'}
            label={'Present'}
            {...args}
            checked={macularEdema}
          />
        </RadioGroup>
      </div>
    </Slide>
  )
}

export default MacularEdema;