import { BehaviorSubject } from 'rxjs';
export type Alert = 'success' | 'warning' | 'danger';

export class GlobalStateService {
  public static fullscreen = new BehaviorSubject<boolean>(false);
  public static alertMessage = new BehaviorSubject<string>('');
  public static alertType: Alert = 'success';
  public static examName = new BehaviorSubject<string>('');
  public static numberOfExams = new BehaviorSubject<number>(0);
  public static showHotkeysModal = new BehaviorSubject<boolean>(false);
  public static examImages = new BehaviorSubject<any[]>([]);
  public static numberOfImages = new BehaviorSubject<number>(0);
  public static duration = new BehaviorSubject<number>(0);
  public static checkedImages = new BehaviorSubject<boolean>(false);   
  public static idleModal = new BehaviorSubject<boolean>(false);

  public static getFullscreen = (): boolean => GlobalStateService.fullscreen.getValue();
  public static setFullscreen = (): void => GlobalStateService.fullscreen.next(!GlobalStateService.fullscreen.getValue());
  
  public static getShowHotkeysModal = (): boolean => GlobalStateService.showHotkeysModal.getValue();
  public static setShowHotkeysModal = (): void => GlobalStateService.showHotkeysModal.next(true);
  public static closeShowHotkeysModal = (): void => GlobalStateService.showHotkeysModal.next(false);


  public static setAlertMessage = (message: string): void => GlobalStateService.alertMessage.next(message);
  public static getAlertType = (): string => GlobalStateService.alertType;
  public static setAlertType = (alertType: Alert): void => {
    GlobalStateService.alertType = alertType;
  }

  public static getExamName = (): string => GlobalStateService.examName.getValue();
  public static setExamName = (examName: string): void => GlobalStateService.examName.next(examName);

  public static getNumberOfExams = (): number => GlobalStateService.numberOfExams.getValue();
  public static setNumberOfExams = (numberOfExams: number): void => GlobalStateService.numberOfExams.next(numberOfExams);

  public static setExamImages = (images: any[]): void => GlobalStateService.examImages.next(images);
  public static getExamImages = (): any[] => GlobalStateService.examImages.getValue();

  public static setNumberOfImages = (numberOfExams: number): void => GlobalStateService.numberOfImages.next(numberOfExams);
  public static getNumberOfImages = (): number => GlobalStateService.numberOfImages.getValue();

  public static getCheckedImages = (): boolean => GlobalStateService.checkedImages.getValue();
  public static setCheckedImages = (isChecked: boolean): void => GlobalStateService.checkedImages.next(isChecked);
  public static disableAllImagesChecked = (): void => GlobalStateService.checkedImages.next(false);
  
  public static setDuration = (duration: number): void => GlobalStateService.duration.next(duration);
  public static getDuration = (): number => GlobalStateService.duration.getValue();

  public static getIdleModal = (): boolean => GlobalStateService.idleModal.getValue();
  public static setIdleModal = (): void => GlobalStateService.idleModal.next(true);
  public static closeIdleModal = (): void => GlobalStateService.idleModal.next(false);
}