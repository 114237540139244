import React from 'react';
import {NextGradeAction} from '../actions';
import hotkeys from 'hotkeys-js';

const Next: React.FC<{GradeStateActionHandler: (gradeAction: any) => void, active: boolean}> = (props) => {
  hotkeys('shift+right', function (event, handler) {
    switch (handler.key) {
      case 'shift+right': handleClick();
    }
  });

  const handleClick = () => {
    props.GradeStateActionHandler(new NextGradeAction());
  }
  return (
    <button
      onClick={handleClick}
      disabled={!props.active}
    >
      {'Next ⮞'}
    </button>
  )
}

export default Next;