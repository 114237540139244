import React, { useState } from 'react';
import './navbar.styles.css';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalStateService } from '../../GlobalState.service';

const useStyles = makeStyles((theme) => ({
  navButton: {
    width: '100%',
    margin: '0 auto',
    textTransform: 'none',
    border: '1px solid #007bff',
    color: '#007bff',
    backgroundColor: 'transparent',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#007bff',
      color: '#fff'
    },
    '@media(max-width: 1200px)': {
      fontSize: '12px'
    }
  },
  dropdownMenu: {
    width: '100% !important',
    padding: '0 40px !important'
  }
}));

const Navbar: React.FC<{ userLogout: () => void, isLoggedUser: boolean }> = (props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="navbar container-fluid">
      <div className="row navbar-row">
        <div className="col-sm-3">
          <h2 className="menu-item">
            IDx-CEM
          </h2>
        </div>
        <div className="col-sm-6 informational-purpose">
          <h4 className='menu-item informational-message'>
            For informational purposes only and not intended for diagnostic use.
          </h4>
        </div>
        <div id="authElement" className="col-sm-3">
          {
            props.isLoggedUser
              ? <>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.navButton}>
                  {localStorage.getItem('username')}
                  <span className='spanArrowBtn'>▼</span>
                </Button>

                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    className={classes.dropdownMenu}
                    onClick={() => {
                      handleClose()
                      props.userLogout()
                    }}>Logout</MenuItem>
                </Menu>
              </>
              : null
          }
        </div>
      </div>
    </div>
  );
}

export default Navbar;